<template>
    <loading-animation class="m-3" v-if="DataLoading"></loading-animation>
    <div class="m-4" v-else>
        <b-form :save-url="updateUrl" @success="formSuccess" v-slot="{model, loading}" :initial-data="details">
            <div class="row">
                <div class="col">
                    <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                     :disabled="loading"/>
                </div>
                <div class="col">
                    <validated-input label="Email" name="Email" type="email" v-model="model.email"
                                     :rules="{required:true}"
                                     :disabled="loading"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <validated-input label="Mobile Number" name="Mobile Number" v-model="model.mobile_number"
                                     :rules="{required:true}" type="number"
                                     :disabled="loading"/>
                </div>
                <div class="col">
                    <validated-input type="date" label="Date of Birth" name="Dob"
                                     v-model="model.dob" :rules="{required:true}"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <validated-vue-select name="Gender" :options="genderOptions" :rules="{required:true}"
                                          label="Gender" v-model="model.gender"/>
                </div>
                <div class="col">
                    <validated-vue-select name="Channel" :options="channelOptions" :rules="{required:true}"
                                          label="Channel" v-model="model.channel"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <validated-ajax-vue-select name="Group" :url="groupOptions" :rules="{required:true}"
                                               label="Group" v-model="model.group"/>
                </div>
                <div class="col">
                    <validated-input type="password" label="Password" name="Password" v-model="model.password"
                                     :rules="{required:true}" :disabled="loading"/>
                </div>
            </div>
            <btn icon="" text="Update" size="sm" :loading="loading" loading-text="Updating..."/>
        </b-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../../data/urls';

export default {
    name  : 'CustomerInformation',
    props : { id : { type : Number } },
    data () {
        return {
            DataLoading    : false,
            details        : '',
            updateUrl      : urls.salesAdmin.customers.add,
            channelOptions : [
                { value : 'Mobile Application', label : 'Mobile Application' },
                { value : 'Website', label : 'Website' }
            ],
            groupOptions  : urls.salesAdmin.customers.groupOptions,
            genderOptions : [
                { value : 'M', label : 'male' },
                { value : 'F', label : 'female' },
                { value : 'O', label : 'Other' }
            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        formSuccess () {
            this.$notify('Updated Successfully', 'Success', {
                type : 'success'
            });
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.salesAdmin.customers.details, { id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.details = json.data;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
