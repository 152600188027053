<template>
    <div>
        <plane-vue-table :showSearchBox="false" title="Address" :fields="fields" :url="listUrl" :per-page="10"
                         ref="table"
                         :page-number="false">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
                </div>
            </template>
        </plane-vue-table>

        <modal no-close-on-backdrop title="Add Address" ref="addModal" width="45r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <div class="row">
                    <div class="col">
                        <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-input label="Email" name="Email" type="email" v-model="model.email"
                                         :rules="{required:true}"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-input label="Company Name" name="Company Name" v-model="model.company_name" :rules="{required:true}"
                                         :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <validated-ajax-vue-select name="Country" :url="countryOptions" :rules="{required:true}"
                                                   label="Country" v-model="model.country"/>
                    </div>
                    <div class="col-6">
                        <validated-ajax-vue-select name="State" :url="stateOptions" :rules="{required:true}"
                                                   label="State" v-model="model.state"/>
                    </div>
                    <div class="col-6">
                        <validated-ajax-vue-select name="District" :url="districtOptions" :rules="{required:true}"
                                                   label="District" v-model="model.district"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input label="Phone" name="Phone" v-model="model.phone" :rules="{required:true}"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-input label="Postal Code" name="Postal Code" v-model="model.postal_code"
                                         :rules="{required:true}"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-vue-select name="Default Address" :options="defaultAddressOptions"
                                              :rules="{required:true}"
                                              label="Default Address" v-model="model.default_address"/>
                    </div>
                </div>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>
    </div>
</template>

<script>
import urls from '../../../../../data/urls';

export default {
    name : 'AddressListingComponent',
    data () {
        return {
            addUrl                : urls.salesAdmin.customers.addressAdd,
            listUrl               : urls.salesAdmin.customers.addressList,
            countryOptions        : urls.salesAdmin.customers.countryOptions,
            stateOptions          : urls.salesAdmin.customers.stateOptions,
            districtOptions       : urls.salesAdmin.customers.districtOptions,
            defaultAddressOptions : [
                { value : true, label : 'True' },
                { value : false, label : 'False' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'address',
                    sortField : 'address',
                    title     : 'Address'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/sales/address/' + rowData.id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
        }
    }
};
</script>
