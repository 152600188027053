<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Customer # {{ id }}</h4>
            <div class="btn-group">
                <btn size="sm" @click="$router.push('/sales/customer/list/')" text="Back"/>
            </div>
        </div>
        <tabs class="b-1 round-2 of-h">
            <tab title="Information" class="p-0">
                <CustomerInformation :id="id"/>
            </tab>
            <tab title="Address" class="p-0">
                <address-listing-component/>
            </tab>
            <tab title="Invoice" class="p-0">
                <InvoicesListingComponent :user="id"/>
            </tab>
            <tab title="Order" class="p-0">
                <OrderInformationComponent :user="id"/>
            </tab>
        </tabs>
    </div>
</template>

<script>
// import urls from '../../../../data/urls';
// import axios from 'secure-axios';
import InvoicesListingComponent from './Components/InvoicesListingComponent';
import OrderInformationComponent from './Components/OrderInformationComponent';
import CustomerInformation from './Components/CustomerInformation';
import AddressListingComponent from './Components/AddressListingComponent';

export default {
    name       : 'DetailsComponent',
    components : {
        AddressListingComponent,
        CustomerInformation,
        OrderInformationComponent,
        InvoicesListingComponent
    },
    data () {
        return {
            DataLoading : false,
            loading1    : false,
            loading     : false,
            id          : this.$route.params.id
        };
    }
};
</script>

<style scoped>

</style>
